.header-menu-mobile .menu {
  background-color: transparent;
  border: none;
  cursor: pointer;
  display: flex;
  padding: 0;
  transform: rotate(180deg);
}
.header-menu-mobile .line {
  fill: none;
  stroke: #3C364D;
  stroke-width: 6;
  transition: stroke-dasharray 600ms cubic-bezier(0.4, 0, 0.2, 1),
  stroke-dashoffset 600ms cubic-bezier(0.4, 0, 0.2, 1);
  stroke-linecap:round;
  transition: color linear 0.3s;
}
.header-menu-mobile .line1 {
  stroke-dasharray: 60 207;
  stroke-width: 6;
}
.header-menu-mobile .line2 {
  stroke-dasharray: 60 60;
  stroke-width: 6;
  // opacity: .9;
}
.header-menu-mobile .line3 {
  stroke-dasharray: 60 207;
  stroke-width: 6;
}
.header-menu-mobile .opened .line1 {
  stroke-dasharray: 90 207;
  stroke-dashoffset: -134;
  stroke-width: 6;
}
.header-menu-mobile .opened .line2 {
  stroke-dasharray: 1 60;
  stroke-dashoffset: -30;
  stroke-width: 6;
}
.header-menu-mobile .opened .line3 {
  stroke-dasharray: 90 207;
  stroke-dashoffset: -134;
  stroke-width: 6;
}

#appbar-menu-mobile .MuiMenu-list {
  padding: 0 !important;
  width: 100%;
  height: 100%;
  padding-top: 68px !important;
}

#banner .vertical-screen {
  flex-direction: column-reverse !important;
  justify-content: flex-end !important;
  align-items: center !important;
  padding-top: 85px !important;
}

// .vertical-screen-title-box {
//   align-items: center !important;
//   padding-top: 12px !important;
// }

// .vertical-screen-video-box {
//   width: 400px !important;
// }


.Menu-list{
  position: fixed;
  height: 100vh;
  overflow: hidden;
  top: -100vh;
  left: 0;
  opacity: 0;
  z-index: 1000;
  background-color: #fff;
  width: 100%;
  transition: all linear .5s;
  .content{
    padding: .6rem .2rem;
    .nav-top{
      margin-top: .17rem;
      padding-bottom: .44rem;
      border-bottom: 1px solid #000;
    }
    .nav-bottom{
      margin-top: .37rem;
      .item{
        font-size: .16rem;
      }
    }
    .item{
      font-size: .2rem;
      line-height: 1.2;
      padding: .14rem 0;
      font-family: 'HSSBlod';
      font-weight: bold;
      a{
        color: #000;
      }
    }
  }
  &.open{
    top: 0;
    opacity: 1;
  }
}