.swiper {
    width: 100vw;
    height: 100vh;
}
.swiper-container {
    transform: translate3d(0, 0, 0);
    overflow: hidden;
}
.swiper-slide {
    width: 100vw;
    height: 100vh;
    transform: translate3d(0, 0, 0);
}

.mySwiper .swiper-pagination {
    right: 1.06rem !important;
    .swiper-pagination-bullet-active {
        width: 8px;
        height: 24px;
        border-radius: 60px;
        background: #ff4a96;
    }
}

.mySwiper {
    .box {
        width: 100%;
        height: 100%;
        background: url("../../assets/home/headerbg.png") no-repeat top center;
        background-size: cover;
        .header {
            position: fixed;
            top: 0;
            left: 0;
            right: 0;
            z-index: 9999;
            transition: all linear 0.3s;
            .container {
                display: flex;
                justify-content: center;
                align-items: center;
                padding: 0.41rem 3.6rem;
                .space {
                    flex: 1;
                }
                .logo {
                    display: flex;
                    align-items: center;
                    img {
                        height: 0.37rem;
                    }
                    .logoImgae {
                        margin-left: -0.45rem;
                    }
                }
            }
            &-nav {
                display: flex;
                align-items: center;

                &-item {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    margin-right: 0.43rem;
                    cursor: pointer;

                    &:nth-last-child(1) {
                        margin-right: 0;
                    }

                    &:hover,
                    &.active {
                        .header-nav-item-title {
                            // color: $primary-text-color;
                        }

                        .header-nav-item-doll {
                            width: 100%;
                        }
                    }

                    &-title {
                        font-family: "HSSBlod";
                        font-size: 0.22rem;
                        line-height: 1.2;
                        font-weight: bold;
                        letter-spacing: 0px;
                        color: #000000;
                    }

                    &-doll {
                        width: 0vw;
                        height: 0.02rem;
                        transition: all 0.15s;
                        border-radius: 1px;
                        background-color: #000;
                    }
                }
            }
        }

        .banner {
            position: relative;
            padding-bottom: 3.5rem;
            .content {
                position: relative;
                z-index: 2;
                max-width: 10.5rem;
                margin: auto;
                color: #000;
                text-align: center;
                padding-top: 1.4rem;
                .title {
                    font-size: 0.84rem;
                    line-height: 0.98rem;
                    font-weight: bold;
                    font-family: "HSSBlod";
                }
                .tips {
                    font-family: "HSSRegular";
                    font-size: 0.27rem;
                    font-weight: 500;
                    text-align: center;
                    letter-spacing: 0.2em;
                }
                p {
                    font-family: "HSSRegular";
                    font-size: 0.32rem;
                    line-height: 0.38rem;
                    margin-top: 0.13rem;
                }
                .btn_group {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    margin-top: 2.5rem;
                    &:last-child {
                        margin-top: 0.25rem;
                    }
                    button {
                        display: flex;
                        align-items: center;
                        justify-content: flex-start;
                        padding: 0.12rem 0;
                        padding-left: 0.33rem;
                        border-radius: 0.4rem;
                        border: none;
                        min-width: 2.4rem;
                        box-sizing: content-box;
                        background-color: #000;
                        color: #fff;
                        margin: 0 0.16rem;
                        cursor: pointer;
                        img {
                            width: 0.33rem;
                            height: auto;
                            margin-right: 0.19rem;
                        }
                        .text {
                            text-align: left;
                            .name {
                                font-family: "HSSRegular";
                                font-weight: bold;
                                font-size: 0.24rem;
                            }
                        }
                    }
                }
            }
        }
    }

    .self {
        width: 100%;
        height: 100%;
        padding: 0 3.6rem;
        .content {
            display: flex;
            align-items: center;
            justify-content: center;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);

            .left {
                flex: 1;
                // width: 5.03rem;
                position: relative;
                .phone {
                    width: 3rem;
                    margin: auto;
                    position: relative;
                    .contentImg {
                        width: 2.8rem;
                        position: absolute;
                        left: 0.1rem;
                        top: 0.1rem;
                        border-radius: 0.35rem;
                    }
                }
                .left-avatar {
                    width: 1.3rem;
                    left: -0.3rem;
                    bottom: 2.8rem;
                    position: absolute;
                }
                .left-xing {
                    width: 1.3rem;
                    left: -0.4rem;
                    bottom: 0.25rem;
                    position: absolute;
                    // animation: Rotate1 8s linear infinite;
                }
                .right-avatar {
                    width: 1.2rem;
                    right: -0.1rem;
                    bottom: 1.5rem;
                    position: absolute;
                    // animation: firendRotate 15s linear infinite;
                }
            }
            .right {
                flex: 1;
                padding-left: 0.4rem;
                text-align: left;
                min-width: 5.24rem;
                .title {
                    font-size: 0.45rem;
                    line-height: 1.2;
                    font-family: "HSSBlod";
                    font-weight: bold;
                }
                p {
                    margin-top: 0.45rem;
                    font-size: 0.27rem;
                    line-height: 1.5;
                    font-family: "HSSRegular";
                    // animation: bounce 4s cubic-bezier(0.05, 0, 0.2, 1) infinite alternate;
                }
            }
        }
    }

    .life {
        width: 100%;
        height: 100%;
        background: linear-gradient(180deg, #94bbff 0%, #e8e1ff 46%, rgba(255, 255, 255, 0) 100%);
        padding: 0 3.6rem;
        .content {
            display: flex;
            align-items: center;
            justify-content: center;
            align-items: center;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);

            .left {
                flex: 1;
                padding-left: 0.2rem;
                min-width: 5.24rem;
                .text {
                    text-align: left;
                    .title {
                        font-size: 0.45rem;
                        line-height: 1.2;
                        font-family: "HSSBlod";
                        font-weight: bold;
                        min-width: 4.8rem;
                        // animation: bounce 4s cubic-bezier(0.05, 0, 0.2, 1) infinite alternate;
                    }
                    p {
                        margin-top: 0.45rem;
                        font-size: 0.27rem;
                        line-height: 1.5;
                        font-family: "HSSRegular";
                        // animation: bounce 3s cubic-bezier(0.05, 0, 0.2, 1) infinite alternate;
                    }
                }
            }
            .right {
                flex: 1;
                .phone {
                    width: 3rem;
                    margin: auto;
                    position: relative;
                    .contentImg {
                        width: 2.8rem;
                        position: absolute;
                        left: 0.1rem;
                        top: 0.1rem;
                        border-radius: 0.35rem;
                    }
                }
            }
        }
    }

    .swiping {
        width: 100%;
        height: 100%;
        padding: 0 3.6rem;
        .content {
            display: flex;
            align-items: center;
            justify-content: center;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            .left {
                flex: 1;
                position: relative;
                .phone {
                    width: 3rem;
                    margin: auto;
                    position: relative;
                    .contentImg {
                        width: 2.8rem;
                        position: absolute;
                        left: 0.1rem;
                        top: 0.1rem;
                        border-radius: 0.35rem;
                    }
                }
                .left-avatar {
                    width: 1.3rem;
                    left: -0.3rem;
                    top: 1.2rem;
                    position: absolute;
                }
                .left-xing {
                    width: 1.3rem;
                    left: -0.3rem;
                    bottom: 0.1rem;
                    position: absolute;
                }
                .right-avatar {
                    width: 1.3rem;
                    right: -0.1rem;
                    top: 40%;
                    position: absolute;
                }
            }
            .right {
                flex: 1;
                padding-left: 0.4rem;
                text-align: left;
                min-width: 5.24rem;
                .title {
                    font-size: 0.45rem;
                    line-height: 1.2;
                    font-family: "HSSBlod";
                    font-weight: bold;
                }
                p {
                    margin-top: 0.45rem;
                    font-size: 0.27rem;
                    line-height: 1.5;
                    font-family: "HSSRegular";
                    // animation: bounce 3s cubic-bezier(0.05, 0, 0.2, 1) infinite alternate;
                }
            }
        }
    }

    .game {
        width: 100%;
        height: 100%;
        background: linear-gradient(180deg, #e6bbff 0%, #ffe1eb 46%, rgba(255, 255, 255, 0) 100%);
        position: relative;
        padding: 0 3.6rem;

        .content {
            display: flex;
            align-items: center;
            justify-content: center;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            .left {
                flex: 1;
                // padding-left: 0.9rem;
                text-align: left;
                padding-left: 0.4rem;
                min-width: 5.24rem;

                .text {
                    .title {
                        font-size: 0.45rem;
                        line-height: 1.2;
                        font-family: "HSSBlod";
                        font-weight: bold;
                    }
                    p {
                        margin-top: 0.45rem;
                        font-size: 0.27rem;
                        line-height: 1.5;
                        font-family: "HSSRegular";
                        // animation: bounce 3s cubic-bezier(0.05, 0, 0.2, 1) infinite alternate;
                    }
                }
            }
            .right {
                flex: 1;
                position: relative;
                .phone {
                    width: 3rem;
                    margin: auto;
                    position: relative;
                    .contentImg {
                        width: 2.8rem;
                        position: absolute;
                        left: 0.1rem;
                        top: 0.1rem;
                        border-radius: 0.35rem;
                    }
                }
            }
        }
    }

    .yourself {
        width: 100%;
        height: 100%;
        padding: 0 3.6rem;
        .content {
            display: flex;
            align-items: center;
            justify-content: center;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            position: absolute;
            .left {
                flex: 1;
                position: relative;
                .phone {
                    width: 3rem;
                    margin: auto;
                    position: relative;
                    .contentImg {
                        width: 2.8rem;
                        position: absolute;
                        left: 0.1rem;
                        top: 0.1rem;
                        border-radius: 0.35rem;
                    }
                }
                .left-avatar {
                    width: 1rem;
                    left: -0.25rem;
                    bottom: 1.1rem;
                    position: absolute;
                    // animation: Rotate1 8s linear infinite;
                }
                .right-xing {
                    width: 1rem;
                    right: 0.1rem;
                    top: 1rem;
                    position: absolute;
                    // animation: Rotate1 8s linear infinite;
                }
                .right-avatar {
                    width: 1rem;
                    right: 0.1rem;
                    bottom: 2.1rem;
                    position: absolute;
                    // animation: Rotate1 8s linear infinite;
                }
            }
            .right {
                flex: 1;
                text-align: left;
                padding-left: 0.4rem;
                min-width: 5.24rem;
                .title {
                    font-size: 0.45rem;
                    line-height: 1.2;
                    font-family: "HSSBlod";
                    font-weight: bold;
                }
                p {
                    margin-top: 0.45rem;
                    font-size: 0.27rem;
                    line-height: 1.5;
                    font-family: "HSSRegular";
                }
            }
        }
    }

    .nft {
        padding: 0 3.6rem;
        width: 100%;
        height: 100%;
        background: linear-gradient(180deg, #ffed49 0%, #fde1ff 46%, rgba(255, 255, 255, 0) 100%);
        .content {
            display: flex;
            align-items: center;
            justify-content: center;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);

            .left {
                // flex: 2;
                text-align: left;
                padding-left: 0.7rem;
                .title {
                    font-size: 0.4rem;
                    line-height: 1.5;
                    font-family: "HSSBlod";
                    font-weight: bold;
                    text-transform: uppercase;
                    min-width: 7rem;
                }
                .list {
                    .item {
                        margin-top: 0.45rem;
                        // padding-left: 0.1rem;
                        font-weight: bold;
                        display: flex;
                        align-items: baseline;
                        p {
                            font-size: 0.27rem;
                            line-height: 1.2;
                            font-family: "HSSRegular";
                            // animation: bounce 3s cubic-bezier(0.05, 0, 0.2, 1) infinite alternate;
                        }
                        span {
                            padding-right: 0.1rem;
                        }
                    }
                }
            }
            .right {
                flex: 1;
                .img_box {
                    .card {
                        width: 3rem;
                        margin: auto;
                        img {
                            width: 100%;
                        }
                    }
                }
            }
        }
    }

    .footer-box {
        width: 100%;
        height: 100%;
    }
    .token {
        height: 92%;
        .content {
            padding-top: 1rem;
            .title {
                font-size: 0.5rem;
                line-height: 1.2;
                font-family: "HSSBlod";
                font-weight: bold;
                text-align: center;
                text-transform: uppercase;
            }
            .list {
                display: flex;
                justify-content: center;
                margin-top: 1rem;
                .item {
                    height: 5.7rem;
                    padding-bottom: 0.95rem;
                    text-align: center;
                    background-color: #fff;
                    border-radius: 0.2rem;
                    margin: 0 0.4rem;
                    .title {
                        font-size: 0.34rem;
                        line-height: 1.2;
                        font-family: "HSSBlod";
                        font-weight: bold;
                    }
                    .coin_box {
                        width: 1.95rem;
                        margin: auto;
                        margin-top: 0.57rem;
                    }
                    p {
                        margin-top: 0.35rem;
                        font-size: 0.4rem;
                        line-height: 1.2;
                        font-family: "HSSBlod";
                        font-weight: bold;
                    }
                }
            }
            .bottom_title {
                font-size: 0.5rem;
                line-height: 1.2;
                font-family: "HSSBlod";
                font-weight: bold;
                margin-top: 2.1rem;
                padding-bottom: 1.17rem;
                text-align: center;
            }
        }
    }
    .footer {
        height: 10%;
        padding: 0 3.6rem;
        background: #fff;
        color: #000;
        font-size: 0.16rem;
        line-height: 1.25;
        .content {
            display: flex;
            align-items: center;
            justify-content: space-between;
            .nav {
                display: flex;
                align-items: center;
                justify-content: center;
                .shu {
                    width: 0.01rem;
                    height: 0.14rem;
                    background-color: #5e5e5e;
                    margin: 0 0.3rem;
                }
            }
        }
    }
}
.FadeInFrame {
    width: 320px;
    height: 180px;
    background-color: #ffd3d3;
    border: 1px solid #b50042;
    opacity: 0;
}
.FadeInFrame.fadein {
    -webkit-transition: all 1.5s;
    -moz-transition: all 1.5s;
    -ms-transition: all 1.5s;
    -o-transition: all 1.5s;
    transition: all 1.5s;
    opacity: 1;
}

.FadeOutFrame {
    width: 320px;
    height: 180px;
    background-color: #abffe8;
    border: 1px solid #0067aa;
    opacity: 1;
}
.FadeOutFrame.fadeout {
    -webkit-transition: all 1.5s;
    -moz-transition: all 1.5s;
    -ms-transition: all 1.5s;
    -o-transition: all 1.5s;
    transition: all 1.5s;
    opacity: 0;
}

@keyframes firendRotate {
    0% {
        transform: rotate(0);
    }
    100% {
        transform: rotate(360deg);
    }
}

@keyframes Rotate1 {
    0%,
    100% {
        -webkit-transform: rotate(-15deg);
    }
    50% {
        -webkit-transform: rotate(15deg);
    }
}

/*自定义一个透明度从0到1的动画，它的名称是fade-in*/
@keyframes fade-in {
    0% {
        opacity: 0;
    }
    40% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
@-webkit-keyframes fade-in {
    0% {
        opacity: 0;
    }
    40% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@keyframes imgRotate {
    from {
        transform: rotate(-30deg);
    }
    to {
        transform: rotate(30deg);
    }
}

@-webkit-keyframes scale {
    0%,
    100% {
        -webkit-transform: scale(1);
    }
    50% {
        -webkit-transform: scale(1.5);
    }
}

@keyframes move {
    /* 分为四个阶段 */
    0% {
        transform: scale(1);
    }

    25% {
        transform: scale(1.1);
    }

    50% {
        transform: scale(1);
    }

    75% {
        transform: scale(1.1);
    }
}

@keyframes slidein {
    from {
        transform: translateX(-100%);
    }
    to {
        transform: translateX(0px);
    }
}

@keyframes bounce {
    0% {
        transform: translate3d(0, 0, 0);
        text-shadow: rgba(255, 255, 255, 0.4) 0 0 0.05em;
    }
    100% {
        transform: translate3d(0, -1em, 0);
        text-shadow: rgba(255, 255, 255, 0.4) 0 1em 0.35em;
    }
}
