.swiper {
  width: 100vw;
  height: 100vh;
}

.phoneSwiper .swiper-slide {
  width: 100vw;
  height: 100vh;
  text-align: center;
  font-size: 18px;
  background: #fff;

  /* Center slide text vertically */
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}

.phoneSwiper .swiper-pagination {
  right: 0.1rem !important;
  .swiper-pagination-bullet-active {
    width: 7px;
    height: 7px;
    border-radius: 50%;
    background: #ff4a96;
  }
  .swiper-pagination-bullet {
    width: 7px;
    height: 7px;
    border-radius: 50%;
  }
}
.mobileHome {
  background-color: #fff;
  overflow-x: hidden;
  width: 100vw;
  height: 100vh;
  text-align: center;
  position: relative;
  // background-position-y: 0.6rem;
  .header {
    position: fixed;
    left: 0;
    top: 0;
    width: 100vw;
    height: 0.6rem;
    z-index: 1400;
    transition: all linear 0.3s;
    // background: linear-gradient(358deg, #F7D5FF 12%, rgba(218,162,255,0.00) 49%);
    .logo {
      height: 0.6rem;
      display: flex;
      align-items: center;
      justify-content: center;
      path {
        fill: #000;
        transition: color linear 0.3s;
      }
      line {
        stroke: #000;
        transition: color linear 0.3s;
      }
    }
    &.scroll {
      background-color: #fff;
      .header-menu-mobile .line {
        stroke: #000;
      }
      .logo {
        path {
          fill: #fff;
        }
        line {
          stroke: #fff;
        }
      }
    }
  }
  .banner {
    padding-top: 0.6rem;
    width: 100%;
    height: 100%;
    background: url("../../assets/home/phonebg.png") no-repeat top center;
    background-size: cover;
    position: relative;
    .title {
      font-size: 0.34rem;
      text-align: center;
      font-weight: bold;
      padding: 0.4rem 0.2rem 0 0.2rem;
      display: inline-block;
      font-family: "HSSBlod";
    }
    .tips {
      font-family: "HSSRegular";
      font-size: 0.15rem;
      font-weight: 500;
      text-align: center;
      padding-top: 0.1rem;
      margin: 0 auto;
      letter-spacing: 0.1em;
    }
    .pic_box {
      margin-top: 0.59rem;
      position: relative;
      margin-bottom: 0.25rem;
      .right {
        right: 0.2rem;
        width: 2.15rem;
        margin: auto;
      }
    }
    .btn_box {
      position: absolute;
      bottom: 1rem;
      left: 0.2rem;
    }
    .btn_group {
      display: flex;
      align-items: center;
      justify-content: center;
      padding-right: 0.3rem;
      &:last-child {
        margin-top: 0.12rem;
      }
      button {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        padding: 0.1rem 0.2rem;
        border-radius: 0.4rem;
        border: none;
        width: 1.2rem;
        box-sizing: content-box;
        background-color: #000;
        color: #fff;
        margin: 0 0.05rem;
        img {
          width: 0.18rem;
          height: auto;
          margin-right: 0.1rem;
        }
        .text {
          text-align: left;
          .name {
            font-family: "HSSBlod";
            font-weight: bold;
            font-size: 0.12rem;
          }
        }
      }
    }
  }
  .self {
    width: 100%;
    height: 100%;
    padding-top: 0.3rem;
    .top {
      position: relative;
      .phone {
        width: 2rem;
        margin: auto;
        position: relative;
        .contentImg {
          width: 1.86rem;
          position: absolute;
          left: 0.07rem;
          top: 0.07rem;
          border-radius: 0.25rem;
        }
      }
      .top-avatar1 {
        width: 0.7rem;
        left: 0.2rem;
        bottom: 2.28rem;
        position: absolute;
        // animation: Rotate1 8s linear infinite;
      }
      .top-avatar3 {
        width: 0.7rem;
        left: 0.2rem;
        bottom: 0.28rem;
        position: absolute;
        // animation: Rotate1 8s linear infinite;
      }
      .top-avatar2 {
        width: 0.6rem;
        right: 0.4rem;
        bottom: 1.18rem;
        position: absolute;
      }
    }
    .text {
      // margin-top: 0.2rem;
      text-align: center;
      .title {
        font-size: 0.25rem;
        line-height: 1.2;
        font-family: "HSSBlod";
        font-weight: bold;
        padding-top: 0.15rem;
      }
      p {
        margin-top: 0.1rem;
        font-size: 0.16rem;
        line-height: 1.4;
        padding: 0 0.3rem;
        font-family: "HSSRegular";
      }
    }
  }
  .life {
    height: 100%;
    padding-top: 0.3rem;
    background: linear-gradient(180deg, #94bbff 0%, #e8e1ff 46%, rgba(255, 255, 255, 0) 100%);
    position: relative;
    .text {
      text-align: center;
      .title {
        font-size: 0.25rem;
        line-height: 1.2;
        font-family: "HSSBlod";
        font-weight: bold;
        padding-top: 0.15rem;
      }
      p {
        margin-top: 0.1rem;
        font-size: 0.16rem;
        line-height: 1.4;
        font-family: "HSSRegular";
        padding: 0 0.5rem;
      }
    }

    .right {
      position: relative;
      margin: auto;
      .phone {
        width: 2rem;
        margin: auto;
        position: relative;
        .contentImg {
          width: 1.86rem;
          position: absolute;
          left: 0.07rem;
          top: 0.07rem;
          border-radius: 0.25rem;
        }
      }
      .icon {
        width: 0.37rem;
        position: absolute;
        left: 0.4rem;
        bottom: 1.45rem;
      }
      .title {
        width: 1.38rem;
        position: absolute;
        left: 0.19rem;
        bottom: 0.6rem;
      }
      .slog {
        position: absolute;
        width: 1.94rem;
        right: 0.15rem;
        top: 1.45rem;
      }
    }
  }
  .swiping {
    padding-top: 0.3rem;
    width: 100%;
    height: 100%;
    .top {
      width: 3.13rem;
      position: relative;
      margin: auto;
      .phone {
        width: 2rem;
        margin: auto;
        position: relative;
        .contentImg {
          width: 1.86rem;
          position: absolute;
          left: 0.07rem;
          top: 0.07rem;
          border-radius: 0.25rem;
        }
      }
      .top-avatar1 {
        width: 0.6rem;
        left: 0rem;
        bottom: 2.28rem;
        position: absolute;
      }
      .top-avatar3 {
        width: 0.6rem;
        left: 0rem;
        bottom: 0.28rem;
        position: absolute;
      }
      .top-avatar2 {
        width: 0.6rem;
        right: 0.04rem;
        bottom: 1.8rem;
        position: absolute;
      }
    }
    .text {
      text-align: center;
      .title {
        font-size: 0.25rem;
        line-height: 1.2;
        font-family: "HSSBlod";
        font-weight: bold;
        padding-top: 0.15rem;
      }
      p {
        margin-top: 0.1rem;
        font-size: 0.16rem;
        line-height: 1.4;
        padding: 0 0.5rem;
        font-family: "HSSRegular";
      }
    }
  }
  .game {
    width: 100%;
    height: 100%;
    padding-top: 0.3rem;
    background: linear-gradient(180deg, #e6bbff 0%, #ffe1eb 46%, rgba(255, 255, 255, 0) 100%);
    .top {
      width: 3.13rem;
      position: relative;
      margin: auto;
      .phone {
        width: 2rem;
        margin: auto;
        position: relative;
        .contentImg {
          width: 1.86rem;
          position: absolute;
          left: 0.07rem;
          top: 0.07rem;
          border-radius: 0.25rem;
        }
      }
      .top-avatar1 {
        width: 0.95rem;
        left: -0.3rem;
        bottom: 2.28rem;
        position: absolute;
      }
      .top-avatar3 {
        width: 0.95rem;
        left: -0.25rem;
        bottom: 0.28rem;
        position: absolute;
      }
      .top-avatar2 {
        width: 0.9rem;
        right: 0.04rem;
        top: 1.18rem;
        position: absolute;
        transform: rotate(6deg);
      }
    }
    .text {
      text-align: center;
      .title {
        font-size: 0.25rem;
        line-height: 1.2;
        font-family: "HSSBlod";
        font-weight: bold;
        padding-top: 0.15rem;
      }
      p {
        margin-top: 0.1rem;
        font-size: 0.16rem;
        line-height: 1.4;
        padding: 0 0.4rem;
        font-family: "HSSRegular";
      }
    }
  }

  .yourself {
    padding-top: 0.3rem;
    width: 100%;
    height: 100%;
    .top {
      // width: 3.13rem;
      position: relative;
      margin: auto;
      .phone {
        width: 2rem;
        margin: auto;
      }
      .top-avatar1 {
        width: 0.6rem;
        right: 0.35rem;
        top: 0.6rem;
        position: absolute;
      }
      .top-avatar3 {
        width: 0.6rem;
        right: 0.35rem;
        bottom: 1.28rem;
        position: absolute;
      }
      .top-avatar2 {
        width: 0.6rem;
        left: 0.25rem;
        bottom: 0.38rem;
        position: absolute;
      }
    }
    .text {
      margin-top: 0.2rem;
      text-align: center;
      .title {
        font-size: 0.25rem;
        line-height: 1.2;
        font-family: "HSSBlod";
        font-weight: bold;
        padding: 0 0.4rem;
      }
      p {
        margin-top: 0.1rem;
        font-size: 0.16rem;
        line-height: 1.4;
        padding: 0 0.4rem;
        font-family: "HSSRegular";
      }
    }
  }

  .nft {
    width: 100%;
    height: 100%;
    background: linear-gradient(180deg, #ffed49 0%, #fde1ff 59%, rgba(255, 255, 255, 0) 100%);
    position: relative;
    padding-top: 0.3rem;
    .content {
      display: flex;
      flex-direction: column;
      .left {
        .title {
          font-size: 0.22rem;
          // line-height: 1.5;
          font-family: "HSSBlod";
          
          text-align: center;
          padding: 0.2rem 0.25rem 0 0.25rem;
          text-transform: uppercase;
          .second {
            padding:0.05rem 0.25rem 0.05rem 0.25rem;;
          }
          div {
            padding: 0.08rem 0;
            font-weight: bold;
          }
        }
        .list {
          text-align: center;
          .item {
            margin-top: 0.1rem;
            padding: 0 0.4rem;
            p {
              font-size: 0.16rem;
              line-height: 1.2;
              font-family: "HSSRegular";
            }
          }
        }
      }
      .right {
        .img_box {
          .card {
            position: relative;
            width: 1.8rem;
            margin: auto;
          }
        }
      }
    }
  }
  .token {
    padding-top: 0.3rem;
    height: 75%;
    width: 100%;
    text-align: center;
    .content {
      .title {
        font-size: 0.34rem;
        line-height: 1.2;
        font-family: "HSSBlod";
        font-weight: bold;
        text-align: center;
        margin-top: 1rem;
        text-transform: uppercase;
      }
      .list {
        display: flex;

        .item {
          height: 3.85rem !important;
          padding-top: 0.5rem;
          padding-left: 0.24rem;

          .title {
            font-size: 0.18rem;
            line-height: 1.2;
            font-family: "HSSBlod";
            font-weight: bold;
          }
          .coin_box {
            width: 0.95rem;
          }
          p {
            margin-top: 0.05rem;
            font-size: 0.18rem;
            line-height: 1.2;
            font-family: "HSSBlod";
            font-weight: bold;
            padding: 0 0.2rem;
          }
        }
      }
      .bottom_title {
        font-size: 0.28rem;
        line-height: 1.2;
        font-family: "HSSBlod";
        font-weight: bold;
        margin-top: 0.5rem;
        padding-bottom: 0.4rem;
        text-align: center;
      }
    }
  }
  .footer {
    width: 100%;
    height: 25%;
    padding: 0.35rem 0.3rem 0.45rem;
    text-align: center;
    background-color: #000;
    .email {
      color: #c5c5c5;
      font-size: 0.14rem;
    }
    .corpy {
      font-size: 0.12rem;
      color: #666;
      margin-top: 0.15rem;
    }
  }
  .footer-box {
    width: 100%;
    height: 100%;
    padding: 0;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
  }
}
