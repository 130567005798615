iframe {
  display: none;
}
body{
  overflow-x: hidden;
}
*{
  padding: 0;
  margin: 0;
  font-weight: normal;
}